import appStrings from '@/app/utility/string.utility';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import DatePicker from 'vue2-datepicker';
export default {
  props: ['partyRowDetails'],
  components: {
    UploadDocument,
    DatePicker
  },
  data() {
    return {
      backgroundVerificationData: [],
      backgroundVerificationFields: [
        {
          key: 'creation_date'
        },
        {
          key: 'status'
          // class: 'col-fix text-center'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'document'
        }
      ],
      partyRequestId: null,
      showBackgroundVerificationModal: false,
      registeredAddress: {
        bgvc_dtl_id: 0,
        add1: null,
        add2: null,
        country: 'India',
        state: null,
        city: null,
        pin: null,
        country_id: 100
      },
      siteAddress: {
        bgvc_dtl_id: 0,
        add1: null,
        add2: null,
        country: 'India',
        state: null,
        city: null,
        pin: null,
        country_id: 100
      },
      natureOfBusiness: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      bgvc_hdr_id: 0,
      bgvc_dtl_id1: 0,
      bgvc_dtl_id0: 0,
      checkAddress: 'unchecked',
      vsetCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      parentId: null,
      addressType: null,
      openKmAuth: null,
      showHistory: false,
      historyType: null,
      currentPage: 1,
      bgvcHdrId: null,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      userId: null,
      status: null,
      directorsData: [],
      directorsFields: [
        // {
        //   key: 'add_row',
        //   label: 'Add',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        {
          key: 'remove_row',
          label: 'Remove',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_name',
          label: 'Name',
          class: 'text-center'
        },

        {
          key: 'bgvc_dir_pan_no',
          label: 'PAN',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_address',
          label: 'Address',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_email',
          label: 'Email',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_phone',
          label: 'Phone',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_father_name',
          label: "Father's Name",
          class: 'text-center'
        }
      ],
      dateOfIncorporation: null,
      noOfDirectors: null,
      dateOfIncorporationInPayload: null
    };
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    this.menuId = this.$store.state.shared.menuId;
    if (this.partyRowDetails) {
      this.partyRequestId = this.partyRowDetails.req_id;
      this.getBackgroundVerificationData();
    }
  },
  methods: {
    getBackgroundVerificationData() {
      const payload = {
        _page: 0,
        _limit: 100,
        party_req_id: this.partyRequestId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getBackgroundVerificationData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.backgroundVerificationData = response.data.data.page;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    initiateBackgroundVerification(type) {
      if (type === 'create') {
        let count = 0;
        for (let i of this.backgroundVerificationData) {
          if (i.status === 'INPROCESS' || i.status === 'DRAFT') {
            count = count + 1;
          }
        }
        if (count > 0) {
          alert('One of the record is already in INPROCESS or DRAFT state');
        } else {
          this.registeredAddress = {
            bgvc_dtl_id: 0,
            add1: null,
            add2: null,
            country: 'India',
            state: null,
            city: null,
            pin: null,
            country_id: 100
          };
          this.siteAddress = {
            bgvc_dtl_id: 0,
            add1: null,
            add2: null,
            country: 'India',
            state: null,
            city: null,
            pin: null,
            country_id: 100
          };
          this.directorsData = [];
          this.natureOfBusiness = null;
          this.checkAddress = 'unchecked';
          this.showBackgroundVerificationModal = true;
          this.status = 'DRAFT';
          this.noOfDirectors = null;
        }
      } else {
        this.registeredAddress = {
          bgvc_dtl_id: 0,
          add1: null,
          add2: null,
          country: null,
          state: null,
          city: null,
          pin: null
        };
        this.siteAddress = {
          bgvc_dtl_id: 0,
          add1: null,
          add2: null,
          country: null,
          state: null,
          city: null,
          pin: null
        };
        this.directorsData = [];
        this.checkAddress = false;
        this.natureOfBusiness = null;
        this.status = type.status;
        this.bgvc_hdr_id = type.bgvc_hdr_id;
        const payload = {
          bgVcHdrId: type.bgvc_hdr_id
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getBackgroundVerificationDetailsData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              // this.isSuccess = true;
              // this.responseMsg = response.data.message;
              let result = response.data.data.bgvc_dtls;
              this.registeredAddress = {
                bgvc_dtl_id: result[0].bgvc_dtl_id,
                add1: result[0].address1,
                add2: result[0].address2,
                country: result[0].country,
                state: result[0].state,
                city: result[0].city,
                pin: result[0].pincode
              };
              this.siteAddress = {
                bgvc_dtl_id: result[1].bgvc_dtl_id,
                add1: result[1].address1,
                add2: result[1].address2,
                country: result[1].country,
                state: result[1].state,
                city: result[1].city,
                pin: result[1].pincode
              };
              this.noOfDirectors = response.data.data.no_of_directors;
              this.dateOfIncorporation = response.data.data.date_of_incorporation;
              if (
                this.registeredAddress.add1 === this.siteAddress.add1 &&
                this.registeredAddress.add2 === this.siteAddress.add2 &&
                this.registeredAddress.country === this.siteAddress.country &&
                this.registeredAddress.state === this.siteAddress.state &&
                this.registeredAddress.city === this.siteAddress.city &&
                this.registeredAddress.pin === this.siteAddress.pin
              ) {
                this.checkAddress = 'checked';
              }
              this.directorsData = response.data.data.bgvc_dir_dtls;
              this.natureOfBusiness = result[1].nature_of_business;
              this.dateOfIncorporation =
                response.data.data.date_of_incorporation;
              this.showBackgroundVerificationModal = true;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    saveBackgroundVerificationData(type) {
      let count = 0;
      for (let i of this.directorsData) {
        if (
          i.bgvc_dir_name === null ||
          i.bgvc_dir_name === '' ||
          i.bgvc_dir_father_name === null ||
          i.bgvc_dir_father_name === '' ||
          i.bgvc_dir_pan_no === null ||
          i.bgvc_dir_pan_no === '' ||
          i.bgvc_dir_phone === null ||
          i.bgvc_dir_phone === '' ||
          i.bgvc_dir_email === null ||
          i.bgvc_dir_email === '' ||
          i.bgvc_dir_address === null ||
          i.bgvc_dir_address === ''
        ) {
          count = count + 1;
        }
      }
      if (
        this.registeredAddress.add1 === null ||
        this.registeredAddress.add1 === '' ||
        this.registeredAddress.country === null ||
        this.registeredAddress.country === '' ||
        this.registeredAddress.state === null ||
        this.registeredAddress.state === '' ||
        this.registeredAddress.city === null ||
        this.registeredAddress.city === '' ||
        this.registeredAddress.pin === null ||
        this.registeredAddress.pin === '' ||
        this.siteAddress.add1 === null ||
        this.siteAddress.add1 === '' ||
        this.siteAddress.country === null ||
        this.siteAddress.country === '' ||
        this.siteAddress.state === null ||
        this.siteAddress.state === '' ||
        this.siteAddress.city === null ||
        this.siteAddress.city === '' ||
        this.siteAddress.pin === null ||
        this.siteAddress.pin === '' ||
        count > 0
      ) {
        alert('Required Mandatory fields');
      } else {
        let directorDetails = [];
        for (let i of this.directorsData) {
          directorDetails.push({
            bgvc_dir_id: i.bgvc_dir_id,
            bgvc_dir_name: i.bgvc_dir_name,
            bgvc_dir_father_name: i.bgvc_dir_father_name,
            bgvc_dir_pan_no: i.bgvc_dir_pan_no,
            bgvc_dir_phone: i.bgvc_dir_phone,
            bgvc_dir_email: i.bgvc_dir_email,
            bgvc_dir_address: i.bgvc_dir_address
          });
        }
        let bgvcDetails = [
          {
            bgvc_dtl_id: this.registeredAddress.bgvc_dtl_id,
            address_type: 'REG',
            country: this.registeredAddress.country,
            state: this.registeredAddress.state,
            city: this.registeredAddress.city,
            pincode: this.registeredAddress.pin,
            address1: this.registeredAddress.add1,
            address2: this.registeredAddress.add2,
            nature_of_business: this.natureOfBusiness
          },
          {
            bgvc_dtl_id: this.siteAddress.bgvc_dtl_id,
            address_type: 'SITE',
            country: this.siteAddress.country,
            state: this.siteAddress.state,
            city: this.siteAddress.city,
            pincode: this.siteAddress.pin,
            address1: this.siteAddress.add1,
            address2: this.siteAddress.add2,
            nature_of_business: this.natureOfBusiness
          }
        ];
        const payload = {
          bgvc_hdr_id: this.bgvc_hdr_id,
          name: this.partyRowDetails.party_name,
          email: this.partyRowDetails.email,
          mobile_no: this.partyRowDetails.mob,
          pan_no: this.partyRowDetails.pan,
          party_req_id: this.partyRequestId,
          status: 'DRAFT',
          no_of_directors: +this.noOfDirectors,
          date_of_incorporation: this.dateOfIncorporation,
          bgvc_dtls: bgvcDetails,
          bgvc_dir_dtls: directorDetails
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/postBackgroundVerificationData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.getBackgroundVerificationData();
              if (type === 'saveClose') {
                this.showBackgroundVerificationModal = false;
              }
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    backgroundVerificationModal(flag) {
      this.showBackgroundVerificationModal = flag;
    },
    checkUncheckAddress() {
      if (this.checkAddress === 'checked') {
        (this.siteAddress.add1 = this.registeredAddress.add1),
          (this.siteAddress.add2 = this.registeredAddress.add2),
          (this.siteAddress.country = this.registeredAddress.country),
          (this.siteAddress.state = this.registeredAddress.state),
          (this.siteAddress.city = this.registeredAddress.city),
          (this.siteAddress.pin = this.registeredAddress.pin);
      }
      //  else if (this.checkAddress === 'unchecked') {
      //   this.siteAddress = {
      //     bgvc_dtl_id: 0,
      //     add1: null,
      //     add2: null,
      //     country: null,
      //     state: null,
      //     city: null,
      //     pin: null
      //   };
      // }
    },
    openValueSetModal(vsetCode, addressType) {
      this.vsetCode = vsetCode;
      this.addressType = addressType;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.parent_value_set_id = 100;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_COUNTRY) {
        if (this.addressType === 'registered') {
          this.registeredAddress.country = item.country;
          this.parentId = item.country_id;
        } else if (this.addressType === 'site') {
          this.siteAddress.country = item.country;
          this.parentId = item.country_id;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        if (this.addressType === 'registered') {
          this.registeredAddress.state = item.state;
          this.checkSameAddress();
        } else if (this.addressType === 'site') {
          this.siteAddress.state = item.state;
          this.checkSameAddress();
        }
      }
    },
    // getOpenKmAuthTokenUploadDocument() {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         this.openKmAuth = response.data.data.token;
    //         if (this.openKmAuth) {
    //           this.showHideHistory(true, 'invoice');
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // showHideHistory(flag, historyType) {
    //   this.showHistory = flag;
    //   this.historyType = historyType;
    // },
    // getUploadedDocDtl() {
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: 100,
    //     menu_id: 446,
    //     sub_menu_id: null,
    //     record_id: this.rfpHeaderId
    //   };
    //   this.documentData = [];
    //   this.$store
    //     .dispatch('intWorkflow/getOpenKmDocDtl', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         if (response.data.data.page.length > 0) {
    //           const docList = response.data.data.page;
    //           this.documentData = docList.map(data => {
    //             data.file_size = this.formatBytes(data.file_size);
    //             return data;
    //           });
    //           this.totalRows = response.data.data.total_elements;
    //         } else {
    //           alert('Document not available for this record');
    //           this.$emit('closeModal');
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    getOpenKmAuthTokenUploadDocument(hdrId) {
      this.bgvcHdrId = hdrId;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    getUploadedDocDtl(hdrId) {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: this.menuId,
        sub_menu_id: 0,
        record_id: hdrId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.closeBackgroundVerificationData(hdrId);
              const docList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.directorsData.push({
        bgvc_dir_id: 0,
        bgvc_dir_name: null,
        bgvc_dir_address: null,
        bgvc_dir_email: null,
        bgvc_dir_pan_no: null,
        bgvc_dir_father_name: null,
        bgvc_dir_phone: null
      });
    },
    removeRow(index) {
      if (this.directorsData[index].bgvc_dir_id === 0) {
        this.directorsData.splice(index, 1);
        this.noOfDirectors = this.directorsData.length;
      } else {
        const payload = {
          bgvc_dir_dtls: [
            {
              bgvc_dir_id: this.directorsData[index].bgvc_dir_id
            }
          ]
        };
        this.documentData = [];
        this.$store
          .dispatch('intWorkflow/deleteDirectorDetails', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (response.data.data.page.length > 0) {
                this.initiateBackgroundVerification();
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
      this.directorsData.splice(index, 1);
    },
    upperCaseFunction(panNumber, index) {
      this.directorsData[index].bgvc_dir_pan_no = panNumber.toUpperCase();
    },
    clearVsetValues(value) {
      if (value === 'regCountry') {
        this.registeredAddress.country = null;
      } else if (value === 'regState') {
        this.registeredAddress.state = null;
      } else if (value === 'siteCountry') {
        this.siteAddress.country = null;
      } else if (value === 'siteState') {
        this.siteAddress.state = null;
      }
    },
    // checkSameAddress(value) {
    //   if (
    //     value === 'add1' &&
    //     this.registeredAddress.add1 !== this.siteAddress.add1
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'add1' &&
    //     this.registeredAddress.add1 === this.siteAddress.add1
    //   ) {
    //     this.checkAddress = 'checked';
    //   } else if (
    //     value === 'add2' &&
    //     this.registeredAddress.add2 !== this.siteAddress.add2
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'add2' &&
    //     this.registeredAddress.add2 === this.siteAddress.add2
    //   ) {
    //     this.checkAddress = 'checked';
    //   } else if (
    //     value === 'city' &&
    //     this.registeredAddress.city !== this.siteAddress.city
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'city' &&
    //     this.registeredAddress.city === this.siteAddress.city
    //   ) {
    //     this.checkAddress = 'checked';
    //   } else if (
    //     value === 'pin' &&
    //     this.registeredAddress.pin !== this.siteAddress.pin
    //   ) {
    //     this.checkAddress = 'unchecked';
    //   } else if (
    //     value === 'pin' &&
    //     this.registeredAddress.pin === this.siteAddress.pin
    //   ) {
    //     this.checkAddress = 'checked';
    //   }
    // }
    checkSameAddress() {
      if (
        this.registeredAddress.add1 === this.siteAddress.add1 &&
        this.registeredAddress.add2 === this.siteAddress.add2 &&
        this.registeredAddress.country === this.siteAddress.country &&
        this.registeredAddress.state === this.siteAddress.state &&
        this.registeredAddress.city === this.siteAddress.city &&
        this.registeredAddress.pin === this.siteAddress.pin
      ) {
        this.checkAddress = 'checked';
      } else {
        this.checkAddress = 'unchecked';
      }
    },
    createDirectorRows(number) {
      if(this.directorsData.length !==0 ) {
        let loop = (+number - this.directorsData.length);
        for (let i = 0; i < loop; i++) {
          this.directorsData.push({
            bgvc_dir_id: 0,
            bgvc_dir_name: null,
            bgvc_dir_address: null,
            bgvc_dir_email: null,
            bgvc_dir_pan_no: null,
            bgvc_dir_father_name: null,
            bgvc_dir_phone: null
          });
        }
      } else {
        for (let i = 0; i < +number; i++) {
          this.directorsData.push({
            bgvc_dir_id: 0,
            bgvc_dir_name: null,
            bgvc_dir_address: null,
            bgvc_dir_email: null,
            bgvc_dir_pan_no: null,
            bgvc_dir_father_name: null,
            bgvc_dir_phone: null
          });
        }
      }
      
    },
    disabledDatesFromSysDate(date) {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const monthNames = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
      ];
      this.currentDate = `${day}-${monthNames[month]}-${year}`;
      const restrictedDate = new Date(this.currentDate);
      restrictedDate.setHours(0, 0, 0, 0);
      return date > restrictedDate;
    },
  }
};
